import { graphql } from "gatsby"
import * as React from "react"
import Layout from "../components/Layout"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import InformationBox from "../components/InformationBox"
const shortcodes = { InformationBox }

const AirlinesPageTemplate = ({ data, pageContext }) => {
  const { body, frontmatter } = data.airlines
  const {
    breadcrumb: { crumbs },
  } = pageContext
  crumbs?.map((crumb: any) => {
    crumb.crumbLabel = crumb?.crumbLabel?.replace("-", " ")
  })
  console.log("AirlinesPageTemplate body: ", body)
  console.log("pageContext: ", pageContext)
  console.log("crumbs: ", crumbs)
  return (
    <Layout>
      <Breadcrumb crumbs={crumbs} crumbSeparator=" > " />
      <MDXProvider components={shortcodes}>
        <MDXRenderer>{body}</MDXRenderer>
      </MDXProvider>
    </Layout>
  )
}
export default AirlinesPageTemplate

export const query = graphql`
  query airlines($slug: String) {
    airlines: mdx(slug: { eq: $slug }) {
      frontmatter {
        title
        date
      }
      slug
      body
    }
  }
`
